import { Customer, CustomerActionType } from '@kaa/api/customers';
import { ActionsHandler } from '@kaa/common/handlers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwButton, SwColumn } from '@kaa/ui-flanders/components';
import { COMMON_ROUTES } from '@kaa/common/constants';
import { navigate, Location } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICES_VOUCHERS_ORDER_START_BUY } from '../../constants';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './WlFunctionalHeader.style.scss';

const navigateToMyOrder = () => navigate(SERVICES_VOUCHERS_ORDER_START_BUY);
const RemoveOrderButtonPaths = [COMMON_ROUTES.MAINTENANCE.path];

type WlFunctionalHEaderActionsProps = {
  customers: Customer[];
  selectedCustomerId: string;
};

export const WlFunctionalHeaderActions = ({
  customers,
  selectedCustomerId,
}: WlFunctionalHEaderActionsProps) => {
  const { t } = useTranslation();

  const selectedCustomer = customers.find(
    ({ id }) => selectedCustomerId === id.toString(),
  );

  if (!selectedCustomer) {
    return null;
  }

  return (
    <Location>
      {({ location }) => {
        if (RemoveOrderButtonPaths.includes(location.pathname)) {
          return null;
        }
        return (
          <ActionsHandler
            resourceId={selectedCustomer.resourceId}
            actions={[CustomerActionType.CAN_ORDER]}
          >
            <SwColumn
              width="2"
              widthM="8"
              widthS="12"
              className="site-nav__orderButton"
            >
              <SwButton
                modBlock
                onClick={() => {
                  navigateToMyOrder();
                  sendMainNavigationEventToGTM(
                    EventAction.CLICK,
                    EventAction.ORDER_VOUCHERS,
                  );
                }}
              >
                {t(i18nKeys.general.cta.order)}
              </SwButton>
            </SwColumn>
          </ActionsHandler>
        );
      }}
    </Location>
  );
};

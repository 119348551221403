import { ApiMode } from '@kaa/api/common';
import { RegionCode } from '@kaa/api/customers';
import { AuthProvider, AuthService, AuthServiceMock } from '@kaa/auth/common';
import {
  Logout,
  ChangeTitleRoute,
  ErrorCrashTrackingBoundary,
  RootErrorCrashTrackingBoundary,
} from '@kaa/core-app/common/components';
import { ActionProvider, AlertProvider } from '@kaa/common/context';

import { BuildConfig, getConfig } from '@kaa/core-app/common/config';
import {
  ActiveRouteProvider,
  Blocked,
  DeveloperConsole,
  IdleUserTimeout,
  LoadAuthUser,
  LoadUser,
  PageViewGTMListener,
  PrivateRoute,
} from '@kaa/core-app/customers/components';
import { WlFunctionalHeader } from '@kaa/core-app/customers/components/functional-header-wl/WlFunctionalHeader';
import { UserSignedOutListener } from '@kaa/core-app/customers/components/headless/UserSignedOutListener';
import { getI18nInstance } from '@kaa/core-app/customers/i18n';
import { getPath, getRouterPath, Routes } from '@kaa/core-app/customers/routes';
import {
  AccountScreen,
  BlockedScreen,
  DashboardScreen,
  FiscalAttestationScreen,
  ForbiddenWrongRegion,
  LoginScreen,
  MaintenanceScreen,
  MyOrderDetailPendingScreen,
  MyOrderDetailScreen,
  MyOrderScreen,
  NotFoundScreen,
  PlanALeaveScreen,
  PreferencesScreen,
  PrestationsScreen,
  PrestationsWorkHistoryScreen,
  ProfileScreen,
  RegisterCompleteScreen,
  RegisterScreen,
  RegisterSummaryScreen,
  ServicesVouchersScreen,
  ServiceUnavailableScreen,
  SupportScreen,
  WalletScreen,
  WorksManagementScreen,
} from '@kaa/core-app/customers/screens';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { SwMain, SwPage } from '@kaa/ui-flanders/components';
import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { TITLE_ROUTES } from '@kaa/core-app/customers/constants/titleRoutes';
import { UserAccessTokenExpiringListener } from '@kaa/core-app/customers/components/headless/UserAccessTokenExpiringListener';
import { ConfigLoadFailedFallbackWithTranslation } from './components/ConfigLoadFailedCallbackWithTranslation';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ChangeLanguage } from './components/headless/ChangeLanguage';

export const App: React.FC = () => {
  const config = getConfig();

  if (!config) {
    return <ServiceUnavailableScreen />;
  }

  const {
    app: {
      mode,
      auth: { oidc },
    },
    buildConfig,
  } = config;

  const {
    id,
    regionCode,
    i18n: { availableLanguages },
  } = buildConfig;

  const initialState =
    mode === ApiMode.MOCK
      ? new AuthServiceMock()
      : new AuthService(
          {
            ...oidc,
            monitorSession: false,
          },
          buildConfig as BuildConfig<RegionCode>,
        );

  return (
    <RootErrorCrashTrackingBoundary
      appId={id}
      regionCode={regionCode as RegionCode}
      config={config}
      user={null}
      actions={null}
      errorComponent={ConfigLoadFailedFallbackWithTranslation}
    >
      <div className="vl-u-sticky-gf">
        <I18nextProvider i18n={getI18nInstance()}>
          <AuthProvider initialState={initialState}>
            <ActionProvider>
              <AlertProvider>
                <ErrorCrashTrackingBoundary
                  appId={id as string}
                  regionCode={regionCode as RegionCode}
                  config={config}
                >
                  <ChangeLanguage>
                    <IdleUserTimeout />
                    <UserSignedOutListener
                      redirectUrl={getRouterPath(Routes.LOGIN)}
                    />
                    <UserAccessTokenExpiringListener />
                    <PageViewGTMListener />
                    <LoadAuthUser />
                    <LoadUser />
                    <Header
                      languages={availableLanguages}
                      regionCode={regionCode as RegionCode}
                    />
                    <DeveloperConsole />
                    <SwPage>
                      <SwMain>
                        <ActiveRouteProvider>
                          {({ activeRoute }) => (
                            <>
                              <Blocked activeRoute={activeRoute} />
                              <ChangeTitleRoute
                                activeRoute={activeRoute}
                                titleRoutes={TITLE_ROUTES}
                              />
                              <WlFunctionalHeader
                                className="hideMobile"
                                activeRoute={activeRoute}
                              />
                            </>
                          )}
                        </ActiveRouteProvider>
                        <Router className="mainContent" primary={false}>
                          <NotFoundScreen default />
                          <NotFoundScreen
                            path={getRouterPath(Routes.PAGE_404)}
                          />
                          <ServiceUnavailableScreen
                            path={getRouterPath(Routes.SERVICE_UNAVAILABLE)}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            component={BlockedScreen}
                            path={getRouterPath(Routes.BLOCKED)}
                          />
                          <ForbiddenWrongRegion
                            path={getRouterPath(Routes.FORBIDDEN_WRONG_REGION)}
                            links={[
                              {
                                id: 1,
                                href:
                                  i18nKeys.vl.general.serviceVouchers.website
                                    .url,
                                text: i18nKeys.vl.general.region.name,
                              },
                              {
                                id: 2,
                                href:
                                  i18nKeys.bl.general.serviceVouchers.website
                                    .url,
                                text: i18nKeys.bl.general.region.name,
                              },
                            ]}
                          />
                          <Redirect
                            from={getPath(Routes.LANDING)}
                            to={getPath(Routes.DASHBOARD)}
                            noThrow
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            path={getRouterPath(Routes.DASHBOARD)}
                            component={DashboardScreen}
                          />
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            path={getRouterPath(Routes.ACCOUNT)}
                            component={AccountScreen}
                          >
                            <Redirect
                              from="*"
                              to={getPath(Routes.ACCOUNT_PROFILE)}
                              noThrow
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(Routes.ACCOUNT_PROFILE)}
                              component={ProfileScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(Routes.ACCOUNT_PREFERENCES)}
                              component={PreferencesScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.ACCOUNT_FISCAL_ATTESTATION,
                              )}
                              component={FiscalAttestationScreen}
                            />
                          </PrivateRoute>
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            path={getRouterPath(Routes.PRESTATIONS)}
                            component={PrestationsScreen}
                          >
                            <Redirect
                              from="*"
                              to={getPath(Routes.PRESTATIONS_WORKS_MANAGEMENT)}
                              noThrow
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.PRESTATIONS_WORKS_MANAGEMENT,
                              )}
                              component={WorksManagementScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
                              )}
                              component={PrestationsWorkHistoryScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.PRESTATIONS_PLAN_A_LEAVE,
                              )}
                              component={PlanALeaveScreen}
                            />
                          </PrivateRoute>
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            path={getRouterPath(Routes.SERVICES_VOUCHERS)}
                            component={ServicesVouchersScreen}
                          >
                            <Redirect
                              from="*"
                              to={getPath(Routes.SERVICES_VOUCHERS_WALLET)}
                              noThrow
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.SERVICES_VOUCHERS_WALLET,
                              )}
                              component={WalletScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.SERVICES_VOUCHERS_ORDER,
                              )}
                              component={MyOrderScreen}
                            />
                            <PrivateRoute
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                              path={getRouterPath(
                                Routes.SERVICES_VOUCHERS_ORDER_DETAILS,
                              )}
                              component={MyOrderDetailScreen}
                            />
                            <PrivateRoute
                              path={getRouterPath(
                                Routes.SERVICES_VOUCHERS_ORDER_PENDING,
                              )}
                              component={MyOrderDetailPendingScreen}
                              unauthenticatedRedirectUrl={getRouterPath(
                                Routes.LOGIN,
                              )}
                            />
                          </PrivateRoute>
                          <PrivateRoute
                            unauthenticatedRedirectUrl={getRouterPath(
                              Routes.LOGIN,
                            )}
                            path={getRouterPath(Routes.SUPPORT)}
                            component={SupportScreen}
                          />
                          <RegisterScreen
                            path={getRouterPath(Routes.REGISTER)}
                          />
                          <RegisterCompleteScreen
                            path={getRouterPath(Routes.REGISTER_COMPLETE)}
                          />
                          <RegisterSummaryScreen
                            path={getRouterPath(Routes.REGISTER_SUMMARY)}
                          />
                          {
                            // LoginCallback is html page in public folder
                          }
                          <LoginScreen path={getRouterPath(Routes.LOGIN)} />
                          <Logout path={getRouterPath(Routes.LOGOUT)} />
                          <MaintenanceScreen
                            path={getRouterPath(Routes.MAINTENANCE)}
                          />
                          {
                            // LogoutCallback is html page in public folder
                          }
                        </Router>
                      </SwMain>
                    </SwPage>
                    <Footer
                      languages={availableLanguages}
                      regionCode={regionCode as RegionCode}
                    />
                  </ChangeLanguage>
                </ErrorCrashTrackingBoundary>
              </AlertProvider>
            </ActionProvider>
          </AuthProvider>
        </I18nextProvider>
      </div>
    </RootErrorCrashTrackingBoundary>
  );
};

import { Customer } from '@kaa/api/customers';
import { useAuthState } from '@kaa/auth/common';
import { AvailableLanguages, StorageKeys } from '@kaa/common/enums';
import { getConfig } from '@kaa/core-app/common/config';
import { AuthContext } from '@kaa/core-app/customers/types';
import { useLanguage } from '@kaa/core-app/customers/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import get from 'lodash.get';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const getLanguage = (selectedCustomer?: Customer) => {
  const availableLanguages =
    get(getConfig(), 'buildConfig.i18n.availableLanguages') ||
    Object.values(AvailableLanguages);

  const localStorageLanguage = localStorage.getItem(
    StorageKeys.LAST_SELECTED_LANGUAGE,
  );

  if (
    localStorageLanguage &&
    availableLanguages.includes(localStorageLanguage as AvailableLanguages)
  ) {
    return localStorageLanguage;
  }

  if (
    selectedCustomer &&
    availableLanguages.includes(
      selectedCustomer.language.toLowerCase() as AvailableLanguages,
    )
  ) {
    return selectedCustomer.language.toLowerCase();
  }

  return get(getConfig(), 'buildConfig.i18n.fallbackLanguage');
};

export const ChangeLanguage = ({ children }: PropsWithChildren<any>) => {
  const { t } = useTranslation();
  const config = getConfig();
  const { selectedCustomerId, customers } = useAuthState<AuthContext>();
  const { language, changeLanguage } = useLanguage();

  useEffect(() => {
    if (!customers || !selectedCustomerId) {
      const lngByWebsite = config?.buildConfig.i18n.availableLanguages.find(
        (lng) => {
          return (
            t(i18nKeys.wl.general.serviceVouchers.website.url, {
              lng,
            }) === window.location.origin
          );
        },
      );

      /* /!\ if no customers change lng with the origin /!\ */
      /* /!\ we have a deep link with the lokalise key /!\ */
      if (lngByWebsite) {
        changeLanguage(lngByWebsite);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!customers || !selectedCustomerId) {
      return;
    }

    const selectedCustomer = customers.find(
      ({ id }) => id.toString() === selectedCustomerId,
    );

    const language = getLanguage(selectedCustomer);

    changeLanguage(language);
    // eslint-disable-next-line
  }, [selectedCustomerId, customers]);

  /**
   * Due to the breaking change on the library react-i18next, we have to force
   * the re-render if the language change
   */
  return <Fragment key={language}>{children}</Fragment>;
};

import { Customer } from '@kaa/api/customers';
import { useAuthState } from '@kaa/auth/common';
import { useAsyncCallback } from '@kaa/common/utils';
import { dataTest } from '@kaa/core-app/customers/datatest/keys';
import { AuthContext } from '@kaa/core-app/customers/types';
import { useDispatchUpdateSelectedCustomer } from '@kaa/core-app/customers/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon, SwLink, SwTitle } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderSwitchProfileUserCard } from './HeaderSwitchProfileUserCard';

type HeaderSwitchProfileModalProps = {
  customers: Customer[];
  selectedCustomer: Customer;
};

export const HeaderSwitchProfileList = ({
  customers,
  selectedCustomer,
}: HeaderSwitchProfileModalProps) => {
  const { t } = useTranslation();
  const { logout } = useAuthState<AuthContext>();
  const [{ loading }, logoutFunc] = useAsyncCallback(logout);
  const dispatchUpdateSelectedCustomer = useDispatchUpdateSelectedCustomer();
  return (
    <div className="profile-modal__list">
      <SwTitle tagName="h4" className="vl-u-spacer">
        {t(i18nKeys.bl.switchProfile.modal.title)}
      </SwTitle>
      <SwTitle tagName="h5" className="vl-u-spacer--xsmall">
        {t(i18nKeys.bl.switchProfile.modal.activeAccount.title)}
      </SwTitle>
      <div className="profile-modal__list-item profile-modal__list-item--main vl-u-spacer">
        <HeaderSwitchProfileUserCard customer={selectedCustomer} />
      </div>
      <SwTitle tagName="h5">
        {t(i18nKeys.bl.switchProfile.modal.otherAccount.title)}
      </SwTitle>
      <ul>
        {customers
          .filter(({ id }) => id !== selectedCustomer.id)
          .map((customer) => {
            return (
              <li key={customer.id} className="profile-modal__list-item">
                <SwLink
                  tagName="button"
                  type="button"
                  onClick={() => {
                    dispatchUpdateSelectedCustomer(customer.id);
                    document.documentElement.scrollTop = 0;
                    window.location.reload();
                  }}
                >
                  <HeaderSwitchProfileUserCard customer={customer} />
                </SwLink>
              </li>
            );
          })}
        <li className="profile-modal__list-item">
          <SwLink
            className="vl-u-text--small "
            modBlock
            tagName="button"
            type="button"
            data-testid={dataTest.header.linkLogout}
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              logoutFunc();
            }}
          >
            <SwIcon
              className="user-profile__badge"
              modAfter
              modLarge
              icon={Icon.LOGOUT}
            />

            <strong className="user-profile__name">
              {t(i18nKeys.navigation.logout)}
            </strong>
          </SwLink>
        </li>
      </ul>
    </div>
  );
};

import { useAuthState } from '@kaa/auth/common';
import { RouteActive } from '@kaa/common/types';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwColumn,
  SwGrid,
  SwIcon,
  SwLayout,
  SwLink,
} from '@kaa/ui-flanders/components';
// @ts-ignore
import logo from '@kaa/ui-flanders/themes/_shared/images/Pluxee_Logo_DarkBlue_RGB.png';
import React, { AllHTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { getRoute, Routes } from '../../routes';
import { AuthContext } from '../../types';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './WlFunctionalHeader.style.scss';
import { WlFunctionHeaderTab } from './WlFunctionalHeader.types';
import { getTabs, isActiveTab } from './WlFunctionalHeader.utils';
import { WlFunctionalHeaderActions } from './WlFunctionalHeaderActions';
import { WlFunctionalHeaderSupportMobile } from './WlFunctionalHeaderSupportMobile';

type FunctionalHeaderProps = AllHTMLAttributes<HTMLDivElement> & {
  activeRoute?: RouteActive<Routes> | null;
  closeNav?: () => void;
  className?: string;
};

export const WlFunctionalHeader = ({
  activeRoute,
  className = '',
  closeNav,
}: FunctionalHeaderProps) => {
  const { t } = useTranslation();
  const { customers, selectedCustomerId, authUser, logout } = useAuthState<
    AuthContext
  >();
  const [{ loading }, logoutFunc] = useAsyncCallback(logout);
  const [tabs, setTabs] = useState<WlFunctionHeaderTab[]>();

  useEffect(() => {
    if (activeRoute) {
      setTabs(getTabs(activeRoute.route.id, t));
    }
  }, [activeRoute, t]);

  if (!activeRoute) {
    return null;
  }

  const showBackToInformationLink = activeRoute.route.id === Routes.LOGIN;

  return (
    <>
      <SwColumn className={`${className} site-nav`}>
        <h1 className="site-nav__logo">
          <img alt="" className="site-nav__image" src={logo} />
        </h1>
        <SwLayout>
          <SwGrid modVCenter>
            <SwColumn className="vl-nav">
              <SwGrid>
                <SwColumn>
                  <nav className="site-nav__navigation">
                    <SwColumn
                      className="site-nav__navigation-wrapper"
                      width="10"
                      widthS="12"
                    >
                      <ul
                        style={
                          showBackToInformationLink
                            ? { border: 'none', marginTop: '4.2rem' }
                            : undefined
                        }
                      >
                        {showBackToInformationLink && (
                          <li>
                            <SwLink
                              to={t(
                                i18nKeys.wl.general.serviceVouchers
                                  .publicWebsite.url,
                              )}
                              title={t(
                                i18nKeys.header.funcHeader.cta.backToInfoWebsite
                                  .title,
                              )}
                            >
                              {t(
                                i18nKeys.header.funcHeader.cta.backToInfoWebsite
                                  .title,
                              )}
                            </SwLink>
                          </li>
                        )}
                        {tabs?.map((tab) => {
                          const route = getRoute(tab.id);
                          return (
                            <li
                              key={`tab-${tab.id}`}
                              className={
                                isActiveTab(tab, activeRoute) ? 'active' : ''
                              }
                            >
                              <SwLink
                                aria-expanded={
                                  !!(
                                    tab.children &&
                                    isActiveTab(tab, activeRoute)
                                  )
                                }
                                title={tab.header}
                                to={route.path}
                                onClick={() => {
                                  sendMainNavigationEventToGTM(
                                    EventAction.CLICK,
                                    tab.id,
                                  );
                                }}
                              >
                                {tab.header}
                                {tab.children && (
                                  <SwIcon
                                    className="showMobile navItem-icon"
                                    icon={Icon.ARROW_DOWN}
                                  />
                                )}
                              </SwLink>
                              {tab.children && (
                                <ul className="site-nav__subnavigation">
                                  {tab.children.map((child) => {
                                    const tabChildRoute = getRoute(child.id);
                                    return (
                                      <li
                                        key={`tab-${tab.id}-child-${child.id}`}
                                        className={
                                          isActiveTab(child, activeRoute)
                                            ? 'active'
                                            : ''
                                        }
                                      >
                                        <SwLink
                                          onClick={() => {
                                            if (closeNav) {
                                              closeNav();
                                            }

                                            sendMainNavigationEventToGTM(
                                              tab.id,
                                              child.id,
                                            );
                                          }}
                                          title={child.header}
                                          to={tabChildRoute.path}
                                        >
                                          {child.header}
                                        </SwLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                        <WlFunctionalHeaderSupportMobile />
                        {authUser && (
                          <li className="logout">
                            <SwLink
                              title={t(i18nKeys.navigation.logout)}
                              data-testid={dataTest.header.linkLogout}
                              onClick={(e) => {
                                e.preventDefault();
                                sendMainNavigationEventToGTM(
                                  EventAction.CLICK,
                                  EventAction.LOG_OUT,
                                );
                                if (closeNav) {
                                  closeNav();
                                }
                                logoutFunc();
                              }}
                              disabled={loading}
                            >
                              <SwIcon
                                className="showMobile navItem-icon"
                                icon={Icon.LOGOUT}
                              />
                              {t(i18nKeys.navigation.logout)}
                            </SwLink>
                          </li>
                        )}
                      </ul>
                    </SwColumn>
                    {selectedCustomerId && (
                      <WlFunctionalHeaderActions
                        customers={customers}
                        selectedCustomerId={selectedCustomerId}
                      />
                    )}
                  </nav>
                </SwColumn>
              </SwGrid>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </SwColumn>
    </>
  );
};

import { useAuthState } from '@kaa/auth/common';
import { useAsyncCallback } from '@kaa/common/utils';
import { Modals } from '@kaa/core-app/customers/constants';
import { dataTest } from '@kaa/core-app/customers/datatest/keys';
import { AuthContext } from '@kaa/core-app/customers/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwBadge,
  SwIcon,
  SwLink,
  SwModal,
  SwPopover,
  SwPopverRenderProps,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderSwitchProfileList } from './HeaderSwitchProfileList';

export const HeaderSwitchProfile = () => {
  const { t } = useTranslation();
  const { authUser, customers, selectedCustomerId, logout } = useAuthState<
    AuthContext
  >();
  const [{ loading }, logoutFunc] = useAsyncCallback(logout);

  if (!authUser || !customers || !selectedCustomerId) {
    return <div className="site-bar__profile-mobile" />;
  }

  if (!customers || !selectedCustomerId) {
    return null;
  }

  const selectedCustomer = customers.find(
    ({ id }) => selectedCustomerId === id.toString(),
  );

  if (!selectedCustomer) {
    return null;
  }

  if (customers.length === 1) {
    return (
      <>
        <div className="site-bar__link site-bar__profile">
          <SwLink
            style={{ paddingRight: '1rem' }}
            className="vl-u-text--small "
            tagName="button"
            type="button"
            data-testid={dataTest.header.linkLogout}
            onClick={(e) => {
              e.preventDefault();
              logoutFunc();
            }}
            disabled={loading}
          >
            {t(i18nKeys.navigation.logout).toUpperCase()}&nbsp;
            <SwIcon icon={Icon.LOGOUT} modLarge />
          </SwLink>
          {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
        </div>
        <div className="site-bar__profile-mobile">
          <SwBadge className="profile-badge" icon={Icon.USER_CIRCLE} />
          <span>
            {selectedCustomer.firstName} {selectedCustomer.lastName}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <SwPopover
        className="site-bar__link site-bar__profile"
        icon={Icon.ARROW_DOWN}
        offsetX="5"
        modRight
        advancedLabel={
          <span>
            {selectedCustomer.firstName} {selectedCustomer.lastName}
            <SwIcon modAfter modLarge icon={Icon.USER_CIRCLE} />
            <SwIcon
              modAfter
              modLarge
              icon={Icon.ARROW_DOWN}
              style={{ fontSize: '1.6rem' }}
            />
          </span>
        }
      >
        {({ close }: SwPopverRenderProps) => (
          <HeaderSwitchProfileList
            selectedCustomer={selectedCustomer}
            customers={customers}
          />
        )}
      </SwPopover>
      <button
        type="button"
        className="site-bar__profile-mobile"
        onClick={() => toggleModalById(Modals.HEADER_PROFILE_LIST_MODAL_ID)}
      >
        <SwBadge className="profile-badge" icon={Icon.USER_CIRCLE} />
        <span>
          {selectedCustomer.firstName} {selectedCustomer.lastName}
          <SwIcon
            className="profile-arrowDown"
            modAfter
            modLarge
            icon={Icon.ARROW_DOWN}
          />
        </span>
      </button>
      <SwModal
        id={Modals.HEADER_PROFILE_LIST_MODAL_ID}
        closable
        component={HeaderSwitchProfileList}
        selectedCustomer={selectedCustomer}
        customers={customers}
        confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
      />
    </>
  );
};
